<div class="grid">
  <div class="col-12">
    <div class="surface-card shadow-2 border-round p-4">
      <div class="flex flex-row justify-content-between">
        <span class="text-2xl">การจัดการบทบาท</span>
        <div class="flex gap-2">
          <p-button
            label="กรองข้อมูล"
            [icon]="'pi pi-filter'"
            (onClick)="sidebarVisible3 = true"
          />
          <p-button
            label="เพิ่มรายการ"
            severity="secondary"
            (onClick)="openModalCreate()"
            icon="pi pi-plus-circle"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="surface-card shadow-2 border-round">
      <p-table
        [columns]="cols"
        [paginator]="true"
        [lazy]="true"
        [rows]="pageSize"
        [rowsPerPageOptions]="[10, 20, 50]"
        [first]="page"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        (onLazyLoad)="loadLazy($event)"
        [value]="dataItems"
        scrollHeight="400px"
        [totalRecords]="totalRecords"
        styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="text-center" style="width: 5rem">No.</th>
            <th class="text-center">ชื่อบทบาท</th>
            <th class="text-center">สถานะ</th>
            <th class="text-center">วันที่ทำรายการ</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          @if(isLoad) {
          <tr style="height: 46px">
            <td><p-skeleton /></td>
            <td><p-skeleton /></td>
            <td><p-skeleton /></td>
            <td><p-skeleton /></td>
            <td><p-skeleton /></td>
          </tr>
          } @else {
          <tr style="height: 46px">
            <td class="text-center">
              {{ rowIndex + 1 + page * pageSize }}
            </td>
            <td class="text-center">{{ rowData.roleName }}</td>
            <td class="text-center">
              <span [class]="'status-badge status-' + rowData.status">{{
                rowData.statusDesc
              }}</span>
            </td>
            <td class="text-center">{{ rowData.createDate }}</td>
            <td class="text-center">
              <p-button
                icon="pi pi-pencil"
                class="mr-2"
                severity="info"
                (onClick)="openModalEdit(rowData)"
              />
              <p-button
                icon="pi pi-trash"
                severity="danger"
                (onClick)="confirmDelete($event, rowData)"
              />
            </td>
          </tr>
          }
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="sidebarVisible3" position="top" styleClass="h-15rem">
  <p-fieldset legend="เงื่อนไขในการค้าหา">
    <form [formGroup]="formSearch">
      <div class="formgroup-inline">
        <div class="field">
          <label htmlFor="firstname1" class="p-sr-only">ชื่อบทบาท</label>
          <input pInputText id="name1" type="text" formControlName="txtRoleName" />
        </div>
        <div class="field">
          <label htmlFor="lastname1" class="p-sr-only">สถานะ</label>
          <p-dropdown
          formControlName="ddlStatus"
          [options]="dataStatus"
          optionLabel="name"
          optionValue="code"
          placeholder="--- กรุณาระบุสถานะ ---"
        />
        </div>
        <button pButton label="ค้นหา" [icon]="'pi pi-search'" (click)="search()"></button>
      </div>
    </form>
  </p-fieldset>
</p-sidebar>
<p-toast
  [showTransformOptions]="'translateY(100%)'"
  [showTransitionOptions]="'1000ms'"
  [hideTransitionOptions]="'1000ms'"
  [showTransformOptions]="'translateX(100%)'"
/>
<p-confirmDialog />
