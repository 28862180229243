import { Component, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './app.footer.component.html',
  styles: ``,
})
export class AppFooterComponent {
  layoutService = inject(LayoutService);

  get logo() {
    return this.layoutService.config().colorScheme === 'light'
      ? 'dark'
      : 'white';
  }
}
