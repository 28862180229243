<form [formGroup]="updateForm" class="grid grid-nogutter p-fluid">
  <div class="col-12 field">
    <label htmlFor="name1">ชื่อบทบาท</label>
    <input pInputText id="name1" type="text" formControlName="txtRoleName" />
  </div>
  <div class="col-12 field">
    <label htmlFor="name1">สถานะ</label>
    <p-dropdown
      formControlName="ddlStatus"
      [options]="dataStatus"
      optionLabel="name"
      optionValue="code"
      placeholder="--- กรุณาระบุสถานะ ---"
    />
  </div>
  <div class="col-12 field">
    <label htmlFor="email1">รายละเอียด</label>
    <textarea rows="5" cols="30" pInputTextarea formControlName="txtDesc">
    </textarea>
  </div>
</form>
<div class="flex flex gap-2 justify-content-end">
  <p-button
    label="บันทึก"
    icon="pi pi-save"
    [loading]="isSave"
    (onClick)="save()"
  />
  <p-button
    label="ยกเลิก"
    icon="pi pi-times"
    [outlined]="true"
    severity="danger"
  />
</div>
