import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthData } from '../_models/authModel';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  baseUrl = environment.apiUrl; // URL to web api

  login(username: string, password: string): Observable<AuthData> {
    return this.http.post<AuthData>(
      `${this.baseUrl}/auth/login`,
      {
        email: username,
        password,
      },
      this.httpOptions
    );
  }
}
