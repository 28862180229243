import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RoleService } from 'src/app/_services/role.service';
import { StorageService } from 'src/app/_services/storage.service';

import { BaseService } from 'src/app/_services/base.service';

@Component({
  selector: 'app-role-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule
  ],
  templateUrl: './role-edit.component.html',
  styleUrl: './role-edit.component.scss',
})
export class RoleEditComponent implements OnInit {
  dynamicDialogRef = inject(DynamicDialogRef);
  dynamicDialogConfig = inject(DynamicDialogConfig);
  roleService = inject(RoleService);
  storageService = inject(StorageService);
  baseService = inject(BaseService);

  isSave = false;
  dataStatus = this.baseService.getStatus();
  roleId = this.dynamicDialogConfig.data.role.id;
  

  updateForm = new FormGroup({
    txtRoleName: new FormControl<string>('', [Validators.required]),
    ddlStatus: new FormControl<string>('', [Validators.required]),
    txtDesc: new FormControl<string>(''),
  });

  ngOnInit(): void {
    const role = this.dynamicDialogConfig.data.role; 
    this.updateForm.controls.txtRoleName.setValue(role.roleName);
    this.updateForm.controls.ddlStatus.setValue(role.status.toUpperCase());
    this.updateForm.controls.txtDesc.setValue(role.description);
  }

  validateForm() {
    if (this.updateForm.invalid) {
      for (const controlName in this.updateForm.controls) {
        const control = this.updateForm.get(controlName);
        if (control) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      }
      return false;
    } else {
      return true;
    }
  }

  save() {
    if (this.validateForm()) {

      const user = this.storageService.getUser();
      this.isSave = true;

      this.roleService.updateRole(
        this.roleId,
        this.updateForm.get('txtRoleName')?.value as string,
        this.updateForm.get('txtDesc')?.value as string,
        this.updateForm.get('ddlStatus')?.value as string,
        user.staffCode,
      ).subscribe(() => {
        this.dynamicDialogRef?.close(true);
      });
       
      
    }
  }
}
