import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { RoleService } from 'src/app/_services/role.service';
import { RoleCreateComponent } from '../role-create/role-create.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RoleModel } from 'src/app/_models/roleModel';
import { Column } from 'src/app/_models/baseModel';
import { SkeletonModule } from 'primeng/skeleton';
import { firstValueFrom } from 'rxjs';
import { RoleEditComponent } from '../role-edit/role-edit.component';
import { StorageService } from 'src/app/_services/storage.service';
import { BaseService } from 'src/app/_services/base.service';

@Component({
  selector: 'app-role-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TableModule,
    SidebarModule,
    InputTextModule,
    DropdownModule,
    FieldsetModule,
    DynamicDialogModule,
    ToastModule,
    SkeletonModule,
    ConfirmDialogModule,
  ],
  templateUrl: './role-list.component.html',
  styleUrl: './role-list.component.scss',
  providers: [DialogService, MessageService, ConfirmationService],
})
export class RoleListComponent implements OnInit {
  roleService = inject(RoleService);
  dialogService = inject(DialogService);
  confirmationService = inject(ConfirmationService);
  messageService = inject(MessageService);
  storageService = inject(StorageService); 
  baseService = inject(BaseService);


  dataStatus = this.baseService.getStatus();

  sidebarVisible3: boolean = false;
  ref: DynamicDialogRef | undefined;
  isLoad = false;
  pageSize: number = 10;
  page = 0;
  totalRecords: number = 0;
  dataItems: RoleModel[] = [];
  cols: Column[] = [
    { field: 'roleName', header: 'ชื่อบทบาท' },
    { field: 'statusDesc', header: 'สถานะ' },
  ];

  formSearch = new FormGroup({
    txtRoleName: new FormControl<string>(''),
    ddlStatus: new FormControl<string>(''),
  });

  ngOnInit(): void {
    for (let i = 0; i < this.pageSize; i++) {
      this.dataItems.push({
        id: '',
        roleName: '',
        status: '',
        statusDesc: '',
        createBy: '',
        createDate: '',
        roleCode: '',
        description: '',
      });
    }
  }

  search() {
    this.sidebarVisible3 = false;
    this.getData(
      this.formSearch.get('txtRoleName')!.value as string,
      this.formSearch.get('ddlStatus')!.value as string,
      1,
      this.pageSize
    );
  }

  loadLazy(event: TableLazyLoadEvent) {
    const page = event.first! / event.rows! + 1;
    this.getData('', '', page, event.rows!);
  }

  async getData(
    roleName: string,
    status: string,
    pageNumber: number,
    pageSize: number
  ) {
    this.isLoad = true;
    this.pageSize = pageSize;

    var resp = await firstValueFrom(
      this.roleService.searchRoles(roleName, status, pageNumber, pageSize)
    );

    setTimeout(() => {
      this.isLoad = false;
      this.dataItems = resp.roles;
      this.totalRecords = resp.totalItems;
    }, 1000);
  }

  openModalCreate() {
    this.ref = this.dialogService.open(RoleCreateComponent, {
      header: 'สร้างบทบาท',
      width: '50vw',
      modal: true,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
    });

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.getData('', '', 1, 10);
        this.messageService.add({
          severity: 'success',
          summary: 'สร้างบทบาท',
          detail: 'สร้างบทบาทสำเร็จ',
        });
      }
    });
  }

  openModalEdit(role: RoleModel) {
    this.ref = this.dialogService.open(RoleEditComponent, {
      header: 'แก้ไขบทบาท',
      width: '50vw',
      modal: true,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        role: role,
      },
    });

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.getData('', '', 1, 10);
        this.messageService.add({
          severity: 'success',
          summary: 'แก้ไขบทบาท',
          detail: 'แก้ไขบทบาทสำเร็จ',
        });
      }
    });
  }

  confirmDelete(event: Event, role: RoleModel) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'ต้องการที่จะลบรายการ ?',
      header: 'ยืนยันการลบรายการ',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: async () => {
        await firstValueFrom(
          this.roleService.deleteRole(
            role.id,
            this.storageService.getUser().staffCode
          )
        );

        this.getData('', '', 1, 10);
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'ลบบทบาทสำเร็จ',
        });
      },
      reject: () => {
        return;
      },
    });
  }
}
