import { Component, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { SelectItem } from 'primeng/api';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'app-rightmenu',
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule, SidebarModule],
  templateUrl: './app.rightmenu.component.html',
  styles: ``,
})
export class AppRightmenuComponent {
  layoutService = inject(LayoutService);

  selectedAmount!: SelectItem;

  amount = [
    { label: '*****24', value: { id: 1, name: '*****24', code: 'A1' } },
    { label: '*****75', value: { id: 2, name: '*****75', code: 'A2' } },
  ];

  get visible(): boolean {
    return this.layoutService.state.rightMenuVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.rightMenuVisible = _val;
  }
}
