import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMenuitemComponent } from './app.menuitem.component';
@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, AppMenuitemComponent],
  templateUrl: './app.menu.component.html',
  styles: ``,
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  ngOnInit() {
    this.model = [
      {
        label: 'การจัดการผู้ดูแลระบบ',
        icon: 'pi pi-home',
        items: [
          {
            label: 'จัดการผู้ใช้งาน',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/staff/staff-list'],
          },
          {
            label: 'จัดการบทบาท',
            icon: 'pi pi-fw pi-box',
            routerLink: ['/roles/role-list'],
          },
        ],
      },
      { separator: true },
      {
        label: 'การจัดการข้อมูลการตลาด',
        icon: 'pi pi-bullseye',
        items: [
          {
            label: 'Friend Get Friends',
            icon: 'pi pi-fw pi-thumbs-up',
            routerLink: ['/marketing/get-friends'],
          },
          {
            label: 'Affiliate Program',
            icon: 'pi pi-fw pi-thumbs-up',
            routerLink: ['/marketing/get-affiliates'],
          },
        ],
      },
      { separator: true },
      {
        label:'การจัดการข้อมูล Lead',
        icon: 'pi pi-bullseye',
        items: [
          {
            label: 'Leads',
            icon: 'pi pi-fw pi-id-card',
            routerLink: ['/lead/lead-list'],
          }
        ],
      }
    ];
  }
}
