import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordModule } from 'primeng/password';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from 'src/app/_services/auth.service';
import { firstValueFrom } from 'rxjs';
import { StorageService } from 'src/app/_services/storage.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    PasswordModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [MessageService],
})
export class LoginComponent implements OnInit {

  authService = inject(AuthService);
  storageService = inject(StorageService);
  router = inject(Router);
  messageService = inject(MessageService);


  formLogin = new FormGroup({
    txtUserName: new FormControl<string | null>('', [
      Validators.required,
      Validators.email,
    ]),
    txtPassword: new FormControl<string | null>('', [Validators.required]),
  });

  isLoad = false;

  ngOnInit(): void {
    this.storageService.clean();
  }

  validateForm() {
    if (this.formLogin.invalid) {
      for (const controlName in this.formLogin.controls) {
        const control = this.formLogin.get(controlName);
        if (control) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      }
      return false;
    } else {
      return true;
    }
  }

  async login() {
    if (this.validateForm()) {
      this.isLoad = true;
      var resp = await firstValueFrom(
        this.authService.login(
          this.formLogin.controls['txtUserName'].value as string,
          this.formLogin.controls['txtPassword'].value as string
        )
      );



      if (resp.isSuccess) {
        this.storageService.saveToken(resp.token);
        this.storageService.saveUser(resp.userProfile); 

        this.messageService.add({ severity: 'success', summary: 'Login Success', detail: 'กำลังเข้าสู่ระบบ' });

        setTimeout(() => {
          this.isLoad = false;
          this.router.navigate(['/']);
        }, 2000); 
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Login Fail', detail: resp.message });
        this.isLoad = false;
      }
    }
  }
}
