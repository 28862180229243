import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { AppSidebarComponent } from './app.sidebar.component';
import { LayoutService } from './service/app.layout.service';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';

import { StyleClassModule } from 'primeng/styleclass';
import { StorageService } from '../_services/storage.service';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    AppSidebarComponent,
    AppBreadcrumbComponent,
    StyleClassModule,
  ],
  templateUrl: './app.topbar.component.html',
  styleUrl: './app.topbar.component.scss',
})
export class AppTopbarComponent {
  layoutService = inject(LayoutService);
  storageService = inject(StorageService);
  el = inject(ElementRef);

  router = inject(Router);

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightMenu();
  }

  onSearchClick() {
    this.layoutService.toggleSearchBar();
  }

  onRightMenuClick() {
    this.layoutService.showRightMenu();
  }

  logout() {
    this.router.navigate(['/login']);
  }

  get logo() {
    const logo =
      this.layoutService.config().menuTheme === 'white' ||
      this.layoutService.config().menuTheme === 'orange'
        ? 'dark'
        : 'white';
    return logo;
  }

  get userProfile() {
    return this.storageService.getUser();
  }
}
