<div class="flex h-screen">
  <div
    class="w-full lg:w-5 h-full text-center px-6 py-6 flex flex-column justify-content-center"
  >
    <form [formGroup]="formLogin">
      <div class="flex flex-column align-items-center gap-4">
        <img
          [src]="'assets/layout/logo/sa-color-logo.png'"
          class="h-6rem mt-4"
          alt="diamond-layout"
        />
        <div class="mb-3">
          <h2>Login to your account</h2>
          <p>
            Forgot password?
            <a class="text-primary hover:underline cursor-pointer font-medium"
              >Click here</a
            >
            to reset.
          </p>
        </div>

        <input
          pInputText
          formControlName="txtUserName"
          id="email"
          placeholder="Email"
          class="w-20rem"
        />
        <p-password
          id="password"
          placeholder="Password"
          [feedback]="false"
          inputStyleClass="w-20rem"
          [toggleMask]="true"
          formControlName="txtPassword"
        ></p-password>

        <button
          pButton
          label="Sing In"
          type="button"
          class="w-20rem"
          [loading]="isLoad"
          (click)="login()"
        ></button>
      </div>

      <p class="text-color-secondary font-semibold"></p>
    </form>
  </div>

  <div
    class="w-8 hidden lg:flex flex-column justify-content-between align-items-center px-6 py-6 bg-cover bg-norepeat"
    style="background-image: url('assets/layout/images/login-bg.webp')"
  >
    <div class="mt-auto mb-auto">
      <span class="block text-white text-7xl font-semibold"
        >Siamese Marketing Plaform</span
      >
      <span class="block text-white text-xl mt-4"
        >ในยุคดิจิทัลที่การตลาดเปลี่ยนแปลงอย่างรวดเร็ว Siamese Marketing
        Platform
        ถูกพัฒนาขึ้นเพื่อช่วยธุรกิจไทยสร้างกลยุทธ์ที่แข็งแกร่งและตอบโจทย์ความต้องการของตลาดสมัยใหม่
        ด้วยเครื่องมือที่ทันสมัยและใช้งานง่าย
        แพลตฟอร์มนี้จึงเป็นกุญแจสู่ความสำเร็จในยุคใหม่ของการตลาดดิจิทัล
      </span>
    </div>
    <div class="flex align-items-center gap-5">
      <span class="text-white font-semibold">Siamese Asset PLC - 2024</span>
    </div>
  </div>
</div>
<p-toast 
    [showTransformOptions]="'translateY(100%)'" 
    [showTransitionOptions]="'1000ms'" 
    [hideTransitionOptions]="'1000ms'" 
    [showTransformOptions]="'translateX(100%)'" />