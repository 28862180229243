import { Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginComponent } from './auth/login/login.component';
import { RoleListComponent } from './roles/role-list/role-list.component';
import { authGuard } from './_guard/auth.guard';

export const routes: Routes = [
  {
    path: 'login',
    data: { breadcrumb: 'Auth' },
    component: LoginComponent,
  }, 
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    data: { breadcrumb: 'Siamese Marketing Platform' },
    children: [
      {
        path: 'roles/role-list',
        component: RoleListComponent,
        canActivate: [authGuard],
      },
      {
        path:'staff',
        loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule),
        canActivate: [authGuard],
      },
      {
        path:'marketing',
        loadChildren: () => import('./marketing/marketing.module').then(m => m.MarketingModule),
        canActivate: [authGuard],
      },
      {
        path:'lead',
        loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule),
        canActivate: [authGuard],
      }
    ],
   
  },
  { path: '**', redirectTo: '/login' },
];
