<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="sidebar-header">
    <a [routerLink]="['/']" class="logo">
        <img src="assets/layout/logo/sa-white-logo.png" class="p-0" alt="diamond-layout" style="width: 15rem;" />
    </a>
    <button
      class="layout-sidebar-anchor p-link"
      type="button"
      (click)="anchor()"
    ></button>
  </div>

  <div #menuContainer class="layout-menu-container">
    <app-menu></app-menu>
  </div>
</div>
