import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { RoleService } from 'src/app/_services/role.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-role-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule
  ],
  templateUrl: './role-create.component.html',
  styleUrl: './role-create.component.scss',
})
export class RoleCreateComponent {
  dynamicDialogRef = inject(DynamicDialogRef);
  roleService = inject(RoleService); 
  storageService = inject(StorageService);

  isSave = false;

  createForm = new FormGroup({
    txtRoleName: new FormControl<string>('', [Validators.required]),
    txtDesc: new FormControl<string>(''),
  });

  validateForm() {
    if (this.createForm.invalid) {
      for (const controlName in this.createForm.controls) {
        const control = this.createForm.get(controlName);
        if (control) {
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      }
      return false;
    } else {
      return true;
    }
  }

  save() {
    if (this.validateForm()) {

      const user = this.storageService.getUser();
      this.isSave = true;

      this.roleService.createRole(
        this.createForm.get('txtRoleName')?.value as string,
        this.createForm.get('txtDesc')?.value as string,
        user.staffCode,
      ).subscribe(() => {
        this.dynamicDialogRef?.close(true);
      });
       
      
    }
  }
}
